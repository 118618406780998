import axios, { AxiosResponse, CancelToken } from 'axios';
import { SaveResult } from 'types/common';
import {
    CredentialApplication,
    CredentialApplicationAttachment,
    CredentialApplicationNote,
    CredentialApplicationChangeTypeInput
} from 'types/sp-api';
import { v4 as uuidv4 } from 'uuid';

class CredentialApplicationService {
    public async getAllApplicationsAsync(filter?: string, cancelToken?: CancelToken): Promise<CredentialApplication[]> {
        const response = await axios.get<CredentialApplication[]>(`credentialApplications/`, {
            cancelToken: cancelToken
        });

        return response.data.filter(
            (item) =>
                !filter ||
                item.lastName.toLowerCase().includes(filter.toLowerCase()) ||
                item.firstName.toLowerCase().includes(filter.toLowerCase()) ||
                item.organization?.name?.toLowerCase()?.includes(filter.toLowerCase())
        );
    }

    public async getApplicationAsync(id: number, cancelToken?: CancelToken): Promise<CredentialApplication> {
        const response = await axios.get(`credentialApplications/${id}`, { cancelToken: cancelToken });
        return response.data;
    }

    public async changeApplicationTypeAsync(id: number, newType: number, newTitle?: string): Promise<void> {
        if (id > 0) {
            const data: CredentialApplicationChangeTypeInput = { type: newType, title: newTitle };

            await axios.put<CredentialApplication, AxiosResponse<CredentialApplicationNote>>(
                `credentialApplications/${id}/changeType`,
                data
            );
        }
    }

    public async setFlagAsync(id?: number, flag?: number): Promise<void> {
        if (id && id > 0) {
            await axios.put(`credentialApplications/${id}/flag/${flag}`);
        }
    }

    public async deleteApplicationAsync(applicationId?: number): Promise<void> {
        if (!applicationId) return;

        await axios.delete(`credentialApplications/${applicationId}`);
    }

    public async getAttachmentsAsync(
        id: number,
        cancelToken?: CancelToken
    ): Promise<CredentialApplicationAttachment[]> {
        const response = await axios.get(`credentialApplications/${id}/attachments`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getAttachmentFileAsync(
        applicationId: number,
        attachmentId?: number,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        return axios.get(`credentialApplications/${applicationId}/attachments/${attachmentId}/file`, {
            responseType: 'blob',
            cancelToken: cancelToken
        });
    }

    public async saveAttachmentAsync(
        applicationId: number,
        attachment: CredentialApplicationAttachment,
        attachments: File[],
        cancelToken?: CancelToken
    ): Promise<CredentialApplicationAttachment | undefined> {
        if (attachments.length != 1) return undefined;

        const filename = encodeURIComponent(attachments[0].name);
        const blobName = `CAA_${uuidv4()}_${filename}`;

        attachment.document = { fileName: filename, blobName: blobName };

        const attachmentSend = await axios.post<CredentialApplicationAttachment>(
            `credentialApplications/${applicationId}/attachments`,
            attachment
        );

        const formData = new FormData();
        formData.append('file', attachments[0]);

        await axios.post(`credentialApplications/${applicationId}/attachments/file/${encodeURI(blobName)}`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
            cancelToken: cancelToken
        });

        return attachmentSend.data;
    }

    public async deleteAttachmentAsync(
        applicationId: number,
        attachmentId?: number,
        cancelToken?: CancelToken
    ): Promise<void> {
        await axios.delete(`credentialApplications/${applicationId}/attachments/${attachmentId}`, {
            cancelToken: cancelToken
        });
    }

    public async getNotesAsync(id: number, cancelToken?: CancelToken): Promise<CredentialApplicationNote[]> {
        const response = await axios.get(`credentialApplications/${id}/notes`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getNoteAsync(
        applicationId: number,
        noteId: number,
        cancelToken?: CancelToken
    ): Promise<CredentialApplicationNote> {
        const response = await axios.get(`credentialApplications/${applicationId}/notes/${noteId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveNoteAsync(note: CredentialApplicationNote): Promise<SaveResult> {
        if (note.id && note.id > 0) {
            await axios.put<CredentialApplicationNote, AxiosResponse<CredentialApplicationNote>>(
                `credentialApplications/${note.applicationId}/notes/${note.id}`,
                note
            );
            return { new: false, id: note.id };
        } else {
            const result = await axios.post(`credentialApplications/${note.applicationId}/notes`, note);
            return { new: true, id: result.data.id ?? 0 };
        }
    }

    public async deleteNoteAsync(
        applicationId: number,
        noteId: number,
        cancelToken?: CancelToken
    ): Promise<CredentialApplicationNote> {
        const response = await axios.delete(`credentialApplications/${applicationId}/notes/${noteId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }
}

export default new CredentialApplicationService();
