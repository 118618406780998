import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Grid, Paper, Tab, Tabs } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import ChurchesService from 'services/churchesService';
import WaitScreen from 'components/common/WaitScreen';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import ChurchIcon from '../icons/ChurchIcon';
import { LanguageContext } from 'contexts/languageContext';
import TabPanel from 'components/common/TabPanel';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { Organization, OrganizationContact } from 'types/sp-api';
import ChurchTabOrganization from './ChurchTabOrganization';
import ChurchTabContracts from './ChurchTabContracts';
import { AddressType, OrganizationType, TabItem } from 'types/common';
import ChurchTabPublic from './ChurchTabPublic';
import ContactService from 'services/contactService';
import config from 'config/config';
import constants from 'config/constants';
import { AuthContext } from 'contexts/authContext';
import { UserRole } from 'types/auth';

interface OwnChurchItemProps {
    churchId: string;
}

const OwnChurchItem: FC<OwnChurchItemProps> = ({ churchId }: OwnChurchItemProps) => {
    const [organizationList, setOrganizationList] = useState<Organization[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const { handleError } = useErrorHandler();
    const { T } = useContext(LanguageContext);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [publicOrganization, setPublicOrganization] = useState<OrganizationContact>();
    const { hasRole } = useContext(AuthContext);

    const getOrganizations = useCallback(
        async (id?: number, refreshList?: boolean): Promise<void> => {
            try {
                if (refreshList) {
                    await ContactService.refreshOrganizationsAsync();
                }

                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const organizations = await ChurchesService.getChurchOrganizationsAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setOrganizationList(organizations);
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationQueryFailed'));
            }
        },
        [handleError, T]
    );

    const getPublicOrganization = useCallback(
        async (id: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const publicOrgList = await ContactService.getOrganizationsAsync(
                    undefined,
                    cancelRef.current.token,
                    true,
                    true,
                    false
                );
                if (cancelRef.current) {
                    setPublicOrganization(publicOrgList.find((item) => item.churchId === id));
                }
            } catch (error) {
                handleError(error, T('Error_ChurchQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getOrganizations(parseInt(churchId));
        getPublicOrganization(parseInt(churchId));

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [churchId, getOrganizations, getPublicOrganization]);

    const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const updateOrganizations = (resetTabs?: boolean) => {
        getOrganizations(parseInt(churchId), true);
        if (resetTabs) setSelectedTab(0);
    };

    const tabs = (contactInfo: boolean): TabItem[] => [
        {
            id: 2,
            title: 'Documents',
            hidden: true,
            content: null // <ChurchTabDocuments organizationList={organizationList} />
        },
        {
            id: 3,
            title: 'Contracts',
            hidden: config.CUSTOMER === constants.Customers.Advk || contactInfo,
            content: <ChurchTabContracts organizationList={organizationList} />
        },
        {
            id: 4,
            title: 'Public',
            content: <ChurchTabPublic organization={publicOrganization} />
        }
    ];

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle
                    title={organizationList && organizationList.length > 0 && organizationList[0].name}
                    icon={ChurchIcon}
                    onAddClick={
                        churchId === 'new' ||
                        !(hasRole(UserRole.Admin) || hasRole(UserRole.SuperUser)) ||
                        (organizationList && organizationList.length > 0 && [1, 4].includes(organizationList[0].type))
                            ? undefined
                            : () => {
                                  organizationList.push({
                                      id: -1,
                                      name: '',
                                      businessId: '',
                                      type: OrganizationType.Association,
                                      churchId: parseInt(churchId),
                                      foundationDate: new Date(),
                                      address: { street: '', zipCode: '', country: 'Suomi', type: AddressType.Domestic }
                                  });
                                  setSelectedTab(organizationList.length - 1);
                              }
                    }
                    addButtonTitle={T('Organization_AddNew')}
                />
            </Grid>
            {!organizationList && !publicOrganization && <WaitScreen />}
            {organizationList && (
                <Paper elevation={3}>
                    <Tabs
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={handleChange}
                    >
                        {organizationList.map((item, index) => (
                            <Tab
                                label={item.id === -1 ? T('Organization_NewlyAdded') : item.name}
                                key={index}
                                value={index}
                            />
                        ))}

                        {tabs(organizationList[0]?.type == OrganizationType.ContactInfo)
                            .filter((tab) => !tab.hidden && tab.id !== 0)
                            .map((item, index) => (
                                <Tab
                                    label={T(`ChurchTab_${item.title}`)}
                                    key={index + organizationList.length}
                                    value={index + organizationList.length}
                                    disabled={churchId === 'new'}
                                />
                            ))}
                    </Tabs>
                    {organizationList.map((item, index) => (
                        <TabPanel value={selectedTab} index={index} key={index}>
                            <ChurchTabOrganization organization={item} updateOrganizations={updateOrganizations} />
                        </TabPanel>
                    ))}

                    {tabs(organizationList[0]?.type == OrganizationType.ContactInfo)
                        .filter((tab) => !tab.hidden && tab.id !== 0)
                        .map((item, index) => (
                            <TabPanel
                                key={index + organizationList.length}
                                value={selectedTab}
                                index={index + organizationList.length}
                            >
                                {item.content}
                            </TabPanel>
                        ))}
                </Paper>
            )}
        </Container>
    );
};

export default OwnChurchItem;
