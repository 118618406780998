import React, { FC, useContext, useRef, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    ClickAwayListener,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography
} from '@mui/material';
import DownloadFile from 'components/common/DownloadFile';
import LoadButton from 'components/common/LoadButton';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import ReportService from 'services/reportService';
import { Form, Formik, FormikProps } from 'formik';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import moment from 'moment';
import { KeyValue, MembersListWithAgeReportForm, ReportType } from 'types/common';
import * as Yup from 'yup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormFieldText from 'components/common/FormFieldText';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        year: Yup.number().test('not-value', T('Common_Required'), (value) => !!value && value > 0)
    });
};

interface ReportMembersListWithAgeProps {
    organizationId: number;
    organizationName: string;
}

const ReportMembersListWithAge: FC<ReportMembersListWithAgeProps> = ({
    organizationId,
    organizationName
}: ReportMembersListWithAgeProps) => {
    const { T } = useContext(LanguageContext);
    const [downloadFile, setDownloadFile] = useState(false);
    const [downloadFileCsv, setDownloadFileCsv] = useState(false);
    const [downloadFileSticker3x8, setDownloadFileSticker3x8] = useState(false);
    const [downloadFileSticker3x10, setDownloadFileSticker3x10] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    const thisYear = moment().year();
    const years = [
        { key: thisYear, text: thisYear.toString() },
        { key: thisYear + 1, text: (thisYear + 1).toString() }
    ];
    const orders: KeyValue[] = ['name', 'age', 'birthday'].map((item) => ({
        key: item,
        value: `Report_Order_${item}`
    }));

    return (
        <Formik
            initialValues={{
                year: 0,
                order: 'age',
                filename: T('Report_MembersListWithAgeReportFile').replace('{0}', organizationName),
                allAfterAge: 90,
                startAge: 50
            }}
            onSubmit={() => {
                /* do nothing */
            }}
            validationSchema={getValidationSchema(T)}
            enableReinitialize
        >
            {({ values, dirty, isValid }: FormikProps<MembersListWithAgeReportForm>) => {
                const fetchFile = async (type: ReportType) => {
                    return ReportService.memberListWithAgeAsync(organizationId, values, type);
                };

                return (
                    <Form>
                        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
                            <Grid item xs={8}>
                                <Card>
                                    <CardContent>
                                        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography color="primary" variant="h6">
                                                    {T('Report_SelectTimeRange')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <FormFieldDropDown
                                                    label={T('Common_Year')}
                                                    name="year"
                                                    placeholder={T('Report_SelectYear')}
                                                    values={years}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <FormFieldDropDown
                                                    label={T('Report_Order')}
                                                    name="order"
                                                    placeholder={T('Report_SelectOrder')}
                                                    values={orders}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography>{T('Report_StartAge')}</Typography>
                                                <Grid item xs={3}>
                                                    <FormFieldText name="startAge" />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography>{T('Report_AllAfterAge')}</Typography>
                                                <Grid item xs={3}>
                                                    <FormFieldText name="allAfterAge" />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color="textSecondary">
                                                    {T('Report_MembersListWithAgeInfoText')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item>
                                    <LoadButton
                                        disabled={!dirty || !isValid}
                                        onClick={() => {
                                            setDownloadFile(true);
                                        }}
                                        loading={downloadFile}
                                    >
                                        {T('Report_MembersListWithAgeBtn')}
                                    </LoadButton>
                                    {downloadFile && (
                                        <DownloadFile
                                            fetchFile={() => fetchFile('pdf')}
                                            filename={values.filename}
                                            onSuccess={() => setDownloadFile(false)}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <LoadButton
                                        disabled={!dirty || !isValid}
                                        onClick={() => {
                                            setDownloadFileCsv(true);
                                        }}
                                        loading={downloadFileCsv}
                                    >
                                        {T('Report_MembersListWithAgeCsvBtn')}
                                    </LoadButton>
                                    {downloadFileCsv && (
                                        <DownloadFile
                                            fetchFile={() => fetchFile('csv')}
                                            filename={T('Report_MembersListWithAgeCsvReportFile').replace(
                                                '{0}',
                                                organizationName
                                            )}
                                            onSuccess={() => setDownloadFileCsv(false)}
                                            filetype="text/csv"
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <ButtonGroup
                                        variant="contained"
                                        color="primary"
                                        aria-label="split button"
                                        ref={anchorRef}
                                        disabled={downloadFileSticker3x8 || downloadFileSticker3x10}
                                    >
                                        <LoadButton
                                            disabled={!dirty || !isValid}
                                            onClick={() => {
                                                setDownloadFileSticker3x8(true);
                                            }}
                                            loading={downloadFileSticker3x8 || downloadFileSticker3x10}
                                        >
                                            {T('Report_MembersListWithAgeStickerBtn')}
                                        </LoadButton>
                                        <Button
                                            color="primary"
                                            size="small"
                                            onClick={() => setMenuOpen((prevOpen) => !prevOpen)}
                                            disabled={
                                                downloadFileSticker3x8 || downloadFileSticker3x10 || !dirty || !isValid
                                            }
                                        >
                                            <ArrowDropDownIcon />
                                        </Button>
                                    </ButtonGroup>
                                    <Popper
                                        open={menuOpen}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal
                                        placement="bottom-end"
                                        style={{ zIndex: 999 }}
                                        nonce={undefined}
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin: placement === 'bottom' ? 'top-end' : 'bottom-end'
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                                        <MenuList id="split-button-menu">
                                                            <MenuItem onClick={() => setDownloadFileSticker3x8(true)}>
                                                                {T('Report_MembersListStickerA4_3x8')}
                                                            </MenuItem>
                                                            <MenuItem onClick={() => setDownloadFileSticker3x10(true)}>
                                                                {T('Report_MembersListStickerA4_3x10')}
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                    {downloadFileSticker3x8 && (
                                        <DownloadFile
                                            fetchFile={() => fetchFile('sticker3x8')}
                                            filename={values.filename}
                                            onSuccess={() => setDownloadFileSticker3x8(false)}
                                        />
                                    )}
                                    {downloadFileSticker3x10 && (
                                        <DownloadFile
                                            fetchFile={() => fetchFile('sticker3x10')}
                                            filename={values.filename}
                                            onSuccess={() => setDownloadFileSticker3x10(false)}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReportMembersListWithAge;
