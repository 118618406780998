import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import PersonsService from 'services/personsService';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import { Person, PersonReligiousInfo } from 'types/sp-api';
import moment from 'moment';
import { DeleteDialogState, DialogOpenState, InfoType } from 'types/common';
import DialogBase from 'components/common/DialogBase';
import { Form, Formik, FormikProps } from 'formik';
import FormFieldDate from 'components/common/FormFieldDate';
import FormFieldText from 'components/common/FormFieldText';
import * as Yup from 'yup';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import ViewSubtitle from 'components/common/ViewSubtitle';
import PersonUtils from 'utils/personHelper';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import ButtonAdd from 'components/common/ButtonAdd';
import LoadButton from 'components/common/LoadButton';
import LastModified from 'components/common/LastModified';
import WaitScreen from 'components/common/WaitScreen';
import constants from 'config/constants';
import config from 'config/config';
import { AuthContext } from 'contexts/authContext';

const getValidationSchema = (T: LanguageTextFunc, type: InfoType) => {
    if (type === InfoType.Conversion && config.CUSTOMER === constants.Customers.Advk) {
        return Yup.object().shape({
            info: Yup.string().required(T('Common_Required'))
        });
    }

    return Yup.object().shape({
        type: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0'),
        date: Yup.string().required(T('Common_Required'))
    });
};

interface AdditionalInfoProps {
    person: Person;
    type: InfoType;
}

const AdditionalInfo: FC<AdditionalInfoProps> = ({ person, type }: AdditionalInfoProps) => {
    const { T } = useContext(LanguageContext);
    const { isAdmin } = useContext(AuthContext);
    const [religiousInfos, setReligiousInfos] = useState<PersonReligiousInfo[]>();
    const [openAddDialog, setOpenAddDialog] = useState<DialogOpenState>(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [sending, setSending] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const getNewInfo = (): PersonReligiousInfo => ({
        id: 0,
        location: '',
        date: moment().toDate(),
        personId: person.id ?? 0,
        type: type === InfoType.Baptism ? 2 : 1,
        typeName: ''
    });

    const [selectedInfo, setSelectedInfo] = useState<PersonReligiousInfo>(getNewInfo());

    const getReligiousInfo = useCallback(
        async (id?: number): Promise<void> => {
            if (!id) return;

            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const personReligiousInfos = await PersonsService.getPersonReligiousInfosAsync(
                    id,
                    type,
                    cancelRef.current.token
                );

                if (cancelRef.current) {
                    setReligiousInfos(personReligiousInfos);
                }
            } catch (error) {
                handleError(error, T('Error_PersonReligiousInfoQueryFailed'));
            }
        },
        [handleError, T, type]
    );

    useEffect(() => {
        getReligiousInfo(person.id);

        return () => {
            cancelRef.current?.cancel();
            cancelRef.current = null;
        };
    }, [getReligiousInfo, person.id]);

    const refreshReligiousInfo = () => {
        getReligiousInfo(person.id);
    };

    const deleteReligiousInfo = async () => {
        try {
            setDeleting(true);
            await PersonsService.deleteReligiousInfoAsync(person.id, deleteDialogState?.id);
            handleSuccess(T(`ReligiousInfo_DeleteSuccess_${type}`));
            refreshReligiousInfo();
            setDeleting(false);
        } catch (error) {
            setDeleting(false);
            handleError(error, T(`ReligiousInfo_DeleteFailed_${type}`));
        }
    };

    const saveReligiousInfo = async (info: PersonReligiousInfo) => {
        try {
            setSending(true);
            await PersonsService.savePersonReligiousInfosAsync(person.id, info);
            handleSuccess(T(`ReligiousInfo_SaveSuccess_${type}`));
            refreshReligiousInfo();
            setSending(false);
        } catch (error) {
            setSending(false);
            handleError(error, T(`ReligiousInfo_SaveFailed_${type}`));
        }
    };

    const addReligiousInfo = () => {
        setSelectedInfo(getNewInfo());
        setOpenAddDialog(true);
    };

    return (
        <Grid container sx={{ ml: 2, mt: 2 }}>
            <Grid item xs={12} style={{ marginLeft: -16, marginBottom: 8 }}>
                <ViewSubtitle title={T(`ReligiousInfo_Title_${type}`)} />
            </Grid>
            {!religiousInfos && <WaitScreen />}
            {religiousInfos && religiousInfos.length == 0 && (
                <Grid item xs={12}>
                    <ButtonAdd
                        color="secondary"
                        onClick={() => addReligiousInfo()}
                        small
                        title={T('ReligiousInfo_AddNew')}
                    />
                </Grid>
            )}
            {religiousInfos && religiousInfos?.length > 0 && (
                <>
                    <Formik
                        initialValues={religiousInfos[0]}
                        enableReinitialize
                        onSubmit={saveReligiousInfo}
                        validationSchema={getValidationSchema(T, type)}
                    >
                        {({ values, dirty, isValid, resetForm }: FormikProps<PersonReligiousInfo>) => (
                            <Form>
                                <Grid item xs={12} container spacing={3}>
                                    {!(
                                        type === InfoType.Conversion && config.CUSTOMER === constants.Customers.Advk
                                    ) && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormFieldDate name="date" label={T(`ReligiousInfo_Date_${type}`)} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormFieldText
                                                    label={T(`ReligiousInfo_Location_${type}`)}
                                                    name="location"
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {type === InfoType.Baptism && (
                                        <Grid item xs={12}>
                                            <FormFieldText
                                                label={T(`ReligiousInfo_Operator_${type}`)}
                                                name="operator"
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <FormFieldTextArea rows={4} label={T(`ReligiousInfo_Info`)} name="info" />
                                    </Grid>
                                    <Grid container item xs={12} justifyContent="space-between">
                                        <Grid item>
                                            <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                                {T('Common_Save')}
                                            </LoadButton>
                                            <Button
                                                disabled={!dirty && values.id !== -1}
                                                style={{ marginLeft: 16 }}
                                                onClick={() => {
                                                    if (values.id !== -1) resetForm();
                                                    else setReligiousInfos(undefined);
                                                }}
                                                color="secondary"
                                            >
                                                {T('Common_Cancel')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            {isAdmin() && (
                                                <LoadButton
                                                    loading={deleting}
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() =>
                                                        setDeleteDialogState({
                                                            show: true,
                                                            message: T(`ReligiousInfo_DeleteMessage_${type}`).replace(
                                                                '{0}',
                                                                PersonUtils.FullName(person)
                                                            ),
                                                            id: religiousInfos[0].id ?? 0
                                                        })
                                                    }
                                                >
                                                    {T('Common_Delete')}
                                                </LoadButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box margin={2} />
                                        <LastModified by={values.lastModifiedBy} date={values.lastModifiedDate} />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                    <DialogConfirmation
                        title={T(`ReligiousInfo_ConfirmDelete_${type}`)}
                        message={deleteDialogState?.message}
                        show={deleteDialogState?.show}
                        onClose={() => setDeleteDialogState(undefined)}
                        onOk={deleteReligiousInfo}
                        warning
                    />
                </>
            )}
            <Formik
                initialValues={selectedInfo}
                enableReinitialize
                validationSchema={getValidationSchema(T, type)}
                onSubmit={saveReligiousInfo}
            >
                {({ dirty, isValid, isSubmitting, submitForm }: FormikProps<PersonReligiousInfo>) => (
                    <DialogBase
                        show={!!openAddDialog}
                        title={T(`ReligiousInfo_AddDialogTitle_${type}`)}
                        onOk={submitForm}
                        onClose={() => setOpenAddDialog(false)}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('Common_Save')}
                    >
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                {!(type === InfoType.Conversion && config.CUSTOMER === constants.Customers.Advk) && (
                                    <>
                                        <Grid item xs={12}>
                                            <FormFieldDate name="date" label={T('ReligiousInfo_FieldDate')} />
                                            <Typography color="textSecondary" variant="body2">
                                                {T('ReligiousInfo_HelperText')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormFieldText name="location" label={T('ReligiousInfo_FieldLocation')} />
                                        </Grid>
                                    </>
                                )}
                                {type === InfoType.Baptism && (
                                    <Grid item xs={12}>
                                        <FormFieldText name="operator" label={T('ReligiousInfo_FieldOperator')} />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <FormFieldTextArea
                                        name="info"
                                        label={T(
                                            type === InfoType.Conversion && config.CUSTOMER === constants.Customers.Advk
                                                ? 'ReligiousInfo_Uskoontulo'
                                                : 'ReligiousInfo_FieldInfo'
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
        </Grid>
    );
};

export default AdditionalInfo;
