import React, { FC } from 'react';
import {
    FilledInputProps,
    InputAdornment,
    InputBaseComponentProps,
    InputProps,
    OutlinedInputProps,
    TextField
} from '@mui/material';
import { useField } from 'formik';
import { SvgIconComponent } from '@mui/icons-material';

interface FormFieldTextProps {
    label?: string;
    name: string;
    readonly?: boolean;
    helperText?: string;
    autoFocus?: boolean;
    password?: boolean;
    inputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
    inputTagProps?: InputBaseComponentProps;
    endIcon?: unknown;
    endIconColor?:
        | 'error'
        | 'primary'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning'
        | 'inherit'
        | 'disabled'
        | 'action';
    endIconTooltip?: string;
}

const FormFieldText: FC<FormFieldTextProps> = ({
    label,
    name,
    readonly,
    helperText,
    autoFocus,
    password,
    inputProps,
    inputTagProps,
    endIcon,
    endIconColor,
    endIconTooltip
}: FormFieldTextProps) => {
    const [field, meta] = useField(name);

    const EndIcon = endIcon as SvgIconComponent;

    let finalInputProps = inputProps;

    if (endIcon) {
        finalInputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <EndIcon color={endIconColor ?? 'inherit'} titleAccess={endIconTooltip} />
                </InputAdornment>
            )
        };
    }

    return (
        <TextField
            id={name}
            label={label}
            error={!!meta.error && meta.touched}
            helperText={(!!meta.error && meta.touched && meta.error) ?? helperText}
            fullWidth
            value={field.value ?? ''}
            onBlur={field.onBlur}
            onChange={field.onChange}
            name={field.name}
            disabled={readonly}
            variant="outlined"
            size="small"
            autoFocus={autoFocus}
            type={password ? 'password' : undefined}
            // InputLabelProps={{ shrink: true }}
            InputProps={finalInputProps}
            inputProps={inputTagProps}
        />
    );
};

export default FormFieldText;
