import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { Settings } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { Grid, Typography } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import SettingsService from 'services/settingsService';
import defaults from 'config/defaults';
import constants from 'config/constants';

interface OrganizationSmsSenderProps {
    organizationId: number;
    plainText?: boolean;
}

const OrganizationSmsSender: FC<OrganizationSmsSenderProps> = ({
    organizationId,
    plainText
}: OrganizationSmsSenderProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [setting, setSetting] = useState<Settings>();

    const getSetting = useCallback(
        async (orgId: number, key: string): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const orgSetting = await SettingsService.getSettingAsync(orgId, key, cancelRef.current.token);

                if (cancelRef.current) {
                    if (!orgSetting || !orgSetting.key) {
                        setSetting(defaults.SmsSender);
                    } else {
                        setSetting(orgSetting);
                    }
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationSettingsQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getSetting(organizationId, constants.Settings.SmsSender);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getSetting, organizationId]);

    if (plainText) {
        return <>{setting?.value ?? defaults.SmsSender.value}</>;
    }

    return (
        <Grid container item>
            <Grid style={{ marginLeft: 8 }}>
                <Typography variant="subtitle1" color="textSecondary" noWrap>
                    {`${T('SmsMessage_Sender')}: `}
                </Typography>
            </Grid>
            <Grid style={{ marginLeft: 8 }}>
                <Typography variant="subtitle1" color="textSecondary" noWrap>
                    {setting?.value ?? defaults.SmsSender.value}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default OrganizationSmsSender;
