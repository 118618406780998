import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { Settings, SmsMessageInput, Tag } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import SmsMessagesService from 'services/smsMessagesService';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import LoadButton from 'components/common/LoadButton';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import { useNavigate } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import SettingsService from 'services/settingsService';
import defaults from 'config/defaults';
import constants from 'config/constants';
import { DbCodeSets, KeyValue } from 'types/common';
import CodeSetService from 'services/codeSetService';
import MemberTagService from 'services/memberTagService';
import ReportFilter from 'components/reports/ReportFilter';
import Utils from 'utils/typeHelper';
import WaitScreen from 'components/common/WaitScreen';
import SmsMessagePriceCounter from './SmsMessagePriceCounter';
import RecipientCounter from './RecipientCounter';
import FormFieldText from 'components/common/FormFieldText';
import InfoIcon from '@mui/icons-material/Info';
import LightTooltip from 'components/common/LightTooltip';
import ContactService from 'services/contactService';
import OrganizationSmsSender from './OrganizationSmsSender';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        message: Yup.string().required(T('Common_Required'))
    });
};

interface SmsMessageNewItemProps {
    organizationId: number;
    copyMessageId?: number;
    closeMessage: () => void;
    updateList: () => Promise<void>;
}

const SmsMessageNewItem: FC<SmsMessageNewItemProps> = ({
    organizationId,
    copyMessageId,
    closeMessage,
    updateList
}: SmsMessageNewItemProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const navigate = useNavigate();
    const [message, setMessage] = useState<SmsMessageInput>();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [setting, setSetting] = useState<Settings>();
    const [memberTagTitles, setMemberTagTitles] = useState<Tag[]>([]);
    const [membershipTypes, setMembershipTypes] = useState<KeyValue[]>();
    const [orgType, setOrgType] = useState<number>(0);

    const getCodeSet = useCallback(
        async (codes: DbCodeSets): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const codeValues = await CodeSetService.getCodeSetAsync(codes, cancelRef.current.token);

                if (cancelRef.current) {
                    const keyValues = codeValues.map((item) => ({
                        key: Utils.IsNumber(item.key) ? item.key : parseInt(item.key?.toString() ?? '0'),
                        text: T(`CodeSet_${codes}_${item.value}`)
                    }));
                    keyValues.sort((a: KeyValue, b: KeyValue): number => {
                        if (!a.key || !b.key) return 0;
                        if (a.key > b.key) return 1;
                        if (a.key < b.key) return -1;
                        return 0;
                    });

                    setMembershipTypes(keyValues);
                }
            } catch (error) {
                handleError(error, T('Error_CodeSetQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        const getOrganizationInfo = async (): Promise<void> => {
            cancelRef.current = axios.CancelToken.source();
            const org = await ContactService.getOrganizationAsync(organizationId, cancelRef.current.token);
            if (cancelRef.current) {
                setOrgType(org.type);
            }
        };

        getOrganizationInfo();
    }, [organizationId]);

    const tagSorter = (a: Tag, b: Tag): number => {
        if (!a.name || !b.name) return 0;
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    };

    const getMemberTagTitles = useCallback(
        async (orgId: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const orgMemberTagTitles = await MemberTagService.getMemberTagTitlesAsync(
                    orgId,
                    cancelRef.current.token
                );

                if (cancelRef.current) {
                    orgMemberTagTitles.sort(tagSorter);
                    setMemberTagTitles(orgMemberTagTitles);
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationTagTitleQueryFailed'));
            }
        },
        [handleError, T]
    );

    const getMessage = useCallback(
        async (orgId: number, id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const msg = await SmsMessagesService.getMessageAsync(orgId, id, cancelRef.current.token);

                if (cancelRef.current) {
                    setMessage(msg);
                }
            } catch (error) {
                handleError(error, T('Error_SmsMessageQueryFailed'));
            }
        },
        [handleError, T]
    );

    const getSetting = useCallback(
        async (orgId: number, key: string): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const orgSetting = await SettingsService.getSettingAsync(orgId, key, cancelRef.current.token);

                if (cancelRef.current) {
                    if (!orgSetting || !orgSetting.key) {
                        setSetting(defaults.SmsSender);
                    } else {
                        setSetting(orgSetting);
                    }
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationSettingsQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getSetting(organizationId, constants.Settings.SmsSender);
        getCodeSet('membershipType');
        getMemberTagTitles(organizationId);

        if (copyMessageId) {
            getMessage(organizationId, copyMessageId);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMessage, getSetting, getCodeSet, getMemberTagTitles, copyMessageId, organizationId]);

    const sendMessage = async (newValues: SmsMessageInput): Promise<void> => {
        try {
            await SmsMessagesService.sendMessagesAsync(organizationId, newValues);
            handleSuccess(T('SmsMessage_MessageSentSuccess'));
            updateList();
            navigate('/communication/smsmessages');
        } catch (error) {
            handleError(error, T('SmsMessage_MessageSentFailed'));
        }
    };

    if (!memberTagTitles || !membershipTypes) {
        return <WaitScreen />;
    }

    return (
        <Formik
            initialValues={{
                organizationId: organizationId,
                title: message?.title ?? '',
                message: message?.message ?? '',
                sender: setting?.value ?? '',
                filter: message?.filter ?? {
                    familyContacts: false,
                    membershipTypes: [],
                    memberTags: [],
                    communityTags: []
                },
                recipientCount: 0,
                smsPerRecipient: 0
            }}
            enableReinitialize
            validationSchema={getValidationSchema(T)}
            onSubmit={sendMessage}
        >
            {({
                values,
                dirty,
                isValid,
                isSubmitting,
                resetForm,
                setFieldValue
            }: FormikProps<SmsMessageInput & { recipientCount: number }>) => {
                const updateCount = (count: number) => {
                    if (count != values.msgCount) {
                        setFieldValue('msgCount', count);
                    }
                };

                const updateSmsPerRecipient = (count: number) => {
                    if (count != values.smsPerRecipient) {
                        setFieldValue('smsPerRecipient', count);
                    }
                };

                return (
                    <Form style={{ minWidth: '100%' }}>
                        <Grid item xs={12}>
                            <Card className="message-item">
                                <CardContent
                                    sx={{
                                        backgroundColor: 'white',
                                        borderWidth: 0,
                                        borderBottomWidth: 1,
                                        borderBottomColor: 'primary.main',
                                        borderBottomStyle: 'solid'
                                    }}
                                >
                                    <Grid container item xs={12}>
                                        <Typography
                                            color="primary"
                                            variant="h6"
                                            gutterBottom
                                            style={{ marginRight: 8 }}
                                        >
                                            1. {T('SmsMessage_NameSmsMessage')}
                                        </Typography>
                                        <LightTooltip title={T('SmsMessage_NameSmsMessageTooltip')}>
                                            <InfoIcon color="info" fontSize="small" />
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item>
                                        <FormFieldText name="title" label={T('SmsMessage_FieldTitle')} />
                                    </Grid>
                                    <OrganizationSmsSender organizationId={organizationId} />
                                </CardContent>
                                <CardContent
                                    sx={{
                                        backgroundColor: 'white',
                                        borderWidth: 0,
                                        borderBottomWidth: 1,
                                        borderBottomColor: 'primary.main',
                                        borderBottomStyle: 'solid'
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <ReportFilter
                                            title={`2. ${T('SmsMessage_Recipients')}`}
                                            name={'filter'}
                                            membershipTypes={membershipTypes}
                                            memberTagTitles={memberTagTitles}
                                            noCardLayout
                                            orgType={orgType}
                                        />
                                    </Grid>
                                </CardContent>
                                <CardContent
                                    sx={{
                                        backgroundColor: 'white',
                                        borderWidth: 0,
                                        borderBottomWidth: 1,
                                        borderBottomColor: 'primary.main',
                                        borderBottomStyle: 'solid'
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <RecipientCounter
                                            organizationId={organizationId}
                                            filter={values.filter}
                                            updateCount={updateCount}
                                        />
                                    </Grid>
                                </CardContent>
                                <CardContent sx={{ minHeight: 150 }}>
                                    <Grid container item xs={12}>
                                        <Typography
                                            color="primary"
                                            variant="h6"
                                            gutterBottom
                                            style={{ marginRight: 8 }}
                                        >
                                            3. {T('SmsMessage_WriteMessage')}
                                        </Typography>
                                        <LightTooltip title={T('SmsMessage_WriteSmsMessageTooltip')}>
                                            <InfoIcon color="info" fontSize="small" />
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldTextArea
                                            name="message"
                                            label={T('SmsMessage_FieldMessage')}
                                            rows={12}
                                        />
                                        <SmsMessagePriceCounter updateSmsPerRecipient={updateSmsPerRecipient} />
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Box m={1}>
                                        <LoadButton
                                            style={{ marginRight: 8 }}
                                            loading={isSubmitting}
                                            disabled={!dirty || !isValid}
                                            type="submit"
                                        >
                                            {T('SmsMessage_SendMessage')}
                                        </LoadButton>
                                        <Button
                                            disabled={!dirty}
                                            style={{ marginLeft: 16 }}
                                            onClick={() => {
                                                resetForm();
                                                closeMessage();
                                            }}
                                            color="secondary"
                                        >
                                            {T('Common_Cancel')}
                                        </Button>
                                    </Box>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SmsMessageNewItem;
