import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import * as Yup from 'yup';
import { Formik, FormikProps, Form } from 'formik';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import AdminService from 'services/adminService';

const FILE_SIZE = 10 * 1024 * 1024;
const SUPPORTED_FORMATS = [
    'text/csv',
    'text/x-csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        file: Yup.mixed()
            .test('fileSize', 'File Size is too large', (value) => !value || value.size <= FILE_SIZE)
            .test('fileType', 'Unsupported File Format', (value) => !value || SUPPORTED_FORMATS.includes(value.type))
            .test('fileExists', T('Common_Required'), (value) => value)
    });
};

interface AddressImportForm {
    file?: File;
}

const AdminToolsTabDvvAddressImport: FC = () => {
    const { T } = useContext(LanguageContext);
    const { handleSuccess } = useSuccessHandler();
    const { handleError } = useErrorHandler();
    const [selectedFile, setSelectedFile] = useState<File>();

    const saveAddresses = async (values: any, helpers: any) => {
        if (selectedFile) {
            try {
                await AdminService.uploadAddressFileAsync(selectedFile);
                handleSuccess(T('DvvAddressImport_SaveSuccess'));
            } catch (error) {
                handleError(error, T('DvvAddressImport_SaveFailed'));
            }
            helpers.resetForm();
            setSelectedFile(undefined);
        }
    };

    return (
        <Formik
            initialValues={{ file: undefined }}
            enableReinitialize
            validationSchema={getValidationSchema(T)}
            onSubmit={saveAddresses}
        >
            {({ dirty, isValid, isSubmitting, errors, getFieldHelpers }: FormikProps<AddressImportForm>) => {
                const selectFile = (event: ChangeEvent<HTMLInputElement>): void => {
                    if (!event.currentTarget.files) return;

                    setSelectedFile(event.currentTarget.files[0]);
                    const helper = getFieldHelpers('file');
                    helper.setValue(event.currentTarget.files[0]);
                };

                return (
                    <Form>
                        <Grid container item xs={12} spacing={4} alignContent="flex-start">
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={3}>
                                    <label htmlFor="btn-upload">
                                        <input
                                            id="btn-upload"
                                            name="btn-upload"
                                            style={{ display: 'none' }}
                                            type="file"
                                            onChange={selectFile}
                                        />
                                        <Button
                                            className="btn-choose"
                                            variant="contained"
                                            color="secondary"
                                            component="span"
                                            style={{ height: 40 }}
                                        >
                                            {T('Common_Browse')}
                                        </Button>
                                    </label>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormFieldReadOnly
                                        label={''}
                                        value={selectedFile?.name ?? T('DvvAddressImport_SelectedFile')}
                                    />
                                    {errors.file && (
                                        <Typography color="error" variant="caption" style={{ marginLeft: 14 }}>
                                            {errors.file}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!dirty || isSubmitting || !isValid || !selectFile}
                                    type="submit"
                                >
                                    {T('Common_Upload')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AdminToolsTabDvvAddressImport;
