import axios, { AxiosResponse, CancelToken } from 'axios';
import moment from 'moment';
import {
    MemberListTemplate,
    MemberListWithAgeTemplate,
    OrganizationMembersChangesTemplate,
    MemberTransferCertificateTemplate,
    MemberBaptismCertificateTemplate,
    MemberList,
    ReportParams,
    CsvParams,
    MemberListCsvTemplate,
    PdfParams,
    Members,
    MembershipReportTemplate,
    MemberAddressStickerTemplate,
    SmsUsageListTemplate,
    SmsOrganizationUsage,
    ReportInclude,
    ReportFilter,
    CredentialApplicationReportTemplate,
    CredentialApplicationNotesReportTemplate,
    CredentialApplicationAgendaTemplate,
    CredentialApplicationProcessingMemoTemplate,
    CredentialApplicationCsvTemplate,
    CredentialCardTemplate,
    GlobalGroupRegisterCsvTemplate,
    PersonalInformationReportTemplate,
    OrganizationBoardMembersTemplate,
    DvvMemberListCsvTemplate,
    DvvAddressListCsvTemplate
} from 'types/sp-api';
import {
    BaptismCertificate,
    CongregationRegisterReportForm,
    GlobalGroupRegisterReportForm,
    MembershipReport,
    MembersListReportForm,
    MembersListWithAgeReportForm,
    PersonalInformationReport,
    ReportType,
    SmsUsageListReportForm,
    TransferCertificate
} from 'types/common';
import constants from 'config/constants';
import config from 'config/config';

class ReportService {
    public async generateTransferCertificateAsync(
        certificate: TransferCertificate,
        language: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: MemberTransferCertificateTemplate = {
            language: language,
            outputFilename: certificate.filename,
            templateName: 'MemberTransferCertificate',
            organizationId: certificate.senderOrganizationId,
            membershipId: certificate.membershipId,
            endMembership: certificate.endMembership,
            targetOrganzationId: certificate.targeOrganizationId
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async generateBaptismCertificateAsync(
        certificate: BaptismCertificate,
        language: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: MemberBaptismCertificateTemplate = {
            language: language,
            outputFilename: certificate.filename,
            templateName: 'MemberBaptismCertificate',
            membershipId: certificate.membershipId,
            organizationId: 0
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async generateMembershipReportAsync(
        report: MembershipReport,
        language: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: MembershipReportTemplate = {
            language: language,
            outputFilename: report.filename,
            templateName: 'MembershipReport',
            membershipId: report.membershipId,
            organizationId: 0,
            addSignatureRow: report.addSignatureRow,
            addSsn: report.addSsn,
            addCertificateText: report.addCertificateText
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async generatePersonalInformationReportAsync(
        report: PersonalInformationReport,
        language: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: PersonalInformationReportTemplate = {
            language: language,
            outputFilename: report.filename,
            templateName: 'PersonalInformationReport',
            personId: report.personId,
            organizationId: 0
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async organizationChangesAsync(
        organizationId: number,
        year: number,
        month?: number,
        quarter?: number,
        includeNames?: boolean,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const params = new URLSearchParams();

        if (month && month > 0) {
            params.append('month', month.toString());
        }

        if (quarter && quarter > 0) {
            params.append('quarter', quarter.toString());
        }

        if (includeNames) {
            params.append('includeNames', true.toString());
        }

        const orgChanges = await axios.get<Members>(`/reports/members/${organizationId}/${year}`, {
            params: params,
            cancelToken: cancelToken
        });

        orgChanges.data.arrivedTransferNames?.sort(this.memberListAlphabeticalOrder);
        orgChanges.data.arrivedBaptizedNames?.sort(this.memberListAlphabeticalOrder);
        orgChanges.data.arrivedRecognizedNames?.sort(this.memberListAlphabeticalOrder);
        orgChanges.data.arrivedChildNames?.sort(this.memberListAlphabeticalOrder);
        orgChanges.data.leftTransferNames?.sort(this.memberListAlphabeticalOrder);
        orgChanges.data.leftOtherNames?.sort(this.memberListAlphabeticalOrder);
        orgChanges.data.leftDeceasedNames?.sort(this.memberListAlphabeticalOrder);
        orgChanges.data.transferInNames?.sort(this.memberListAlphabeticalOrder);
        orgChanges.data.transferOutNames?.sort(this.memberListAlphabeticalOrder);

        const data: OrganizationMembersChangesTemplate = {
            language: 'fi',
            outputFilename: `jaseniston_muutokset_${year}.pdf`,
            templateName: 'OrganizationMembersChanges',
            organizationId: organizationId,
            members: orgChanges.data,
            year: year.toString(),
            month: month?.toString() ?? '0',
            quarter: quarter?.toString() ?? '0',
            includeNames: includeNames
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async memberListWithAgeAsync(
        organizationId: number,
        reportForm: MembersListWithAgeReportForm,
        type: ReportType,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        //const params = new URLSearchParams();
        // if (reportForm.allAfterAge) {
        //     params.append('allAfterAge', reportForm.allAfterAge.toString());
        // }

        // const memberList = await axios.get<MemberList[]>(
        //     `/reports/members/${organizationId}/listWithAge/${reportForm.year}`,
        //     { params: params, cancelToken: cancelToken }
        // );

        // memberList.data.sort(this.memberListOrder(reportForm.order));

        const data: MemberListWithAgeTemplate = {
            language: 'fi',
            outputFilename: reportForm.filename,
            templateName: 'MemberListWithAge',
            organizationId: organizationId,
            year: reportForm.year.toString(),
            order: reportForm.order,
            allAfterAge: reportForm.allAfterAge,
            startAge: reportForm.startAge
        };

        if (type === 'csv') {
            data.templateName = 'MemberListWithAgeCsv';

            const csvParams: CsvParams = {
                template: data
            };

            return this.generateCsvFromTemplate(csvParams, cancelToken);
        } else {
            const pdfParams: PdfParams = {
                template: data
            };

            if (type.startsWith('sticker')) {
                const stickerData: MemberAddressStickerTemplate = {
                    language: data.language,
                    outputFilename: data.outputFilename,
                    templateName: 'MemberAddressSticker',
                    organizationId: data.organizationId,
                    size: type,
                    year: reportForm.year.toString(),
                    allAfterAge: reportForm.allAfterAge,
                    startAge: reportForm.startAge
                };

                pdfParams.template = stickerData;
            }

            return this.generatePdfFromTemplate(pdfParams, cancelToken);
        }
    }

    public async memberListAsync(
        organizationId: number,
        reportForm: MembersListReportForm,
        type: ReportType,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const memberList = await this.getMemberListAsync(
            organizationId,
            reportForm.filter,
            reportForm.include,
            cancelToken
        );
        memberList.sort(this.memberListOrder(reportForm.order));

        if (type === 'sticker3x8' || type === 'sticker3x10') {
            const data: MemberAddressStickerTemplate = {
                language: 'fi',
                outputFilename: reportForm.filename,
                templateName: 'MemberAddressSticker',
                organizationId: organizationId,
                memberList: memberList,
                size: type
            };

            const pdfParams: PdfParams = {
                template: data,
                include: reportForm.include
            };

            return this.generatePdfFromTemplate(pdfParams, cancelToken);
        } else if (type === 'csv') {
            const data: MemberListCsvTemplate = {
                language: 'fi',
                outputFilename: reportForm.filename,
                templateName: 'MemberListCsv',
                organizationId: organizationId,
                memberList: memberList
            };

            const csvParams: CsvParams = {
                template: data,
                include: reportForm.include
            };

            return this.generateCsvFromTemplate(csvParams, cancelToken);
        } else {
            const data: MemberListTemplate = {
                language: 'fi',
                outputFilename: reportForm.filename,
                templateName: 'MemberList',
                organizationId: organizationId,
                memberList: memberList,
                groupName: reportForm.groupName,
                globalGroupName: reportForm.globalGroupName,
                filterText: reportForm.filterText
            };

            const pdfParams: PdfParams = {
                template: data,
                include: reportForm.include
            };

            return this.generatePdfFromTemplate(pdfParams, cancelToken);
        }
    }

    public async memberListEmailsOnlyAsync(
        organizationId: number,
        reportForm: MembersListReportForm,
        cancelToken?: CancelToken
    ): Promise<string[]> {
        const memberList = await this.getMemberListAsync(
            organizationId,
            reportForm.filter,
            reportForm.include,
            cancelToken
        );

        // TODO poista henkilöt joilla sähköpostiviestintäkielto!

        return memberList.filter((m) => m.email != undefined && m.email.length > 0).map((m) => m.email ?? '');
    }

    public async memberListExtractAsync(
        organizationId: number,
        type: ReportType,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        let membershipTypeFilter = Array<number>();
        if (config.CUSTOMER == constants.Customers.Advk) {
            membershipTypeFilter = [1, 2]; // only full members
        }

        const reportForm: MembersListReportForm = {
            include: { phone: true, email: true, birthDate: true, age: false },
            filter: { familyContacts: false, membershipTypes: membershipTypeFilter, memberTags: [] },
            filename: ''
        };

        const data: MemberListTemplate = {
            language: 'fi',
            outputFilename: reportForm.filename,
            templateName: 'MemberListExtract',
            organizationId: organizationId
        };

        if (type === 'csv') {
            data.templateName = 'MemberListExtractCsv';

            const csvParams: CsvParams = {
                template: data,
                include: reportForm.include,
                filter: reportForm.filter
            };

            return this.generateCsvFromTemplate(csvParams, cancelToken);
        } else {
            const pdfParams: PdfParams = {
                template: data,
                include: reportForm.include,
                filter: reportForm.filter
            };

            return this.generatePdfFromTemplate(pdfParams, cancelToken);
        }
    }

    public async smsUsageListAsync(
        beginDate?: Date,
        endDate?: Date,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const reportForm: SmsUsageListReportForm = {
            beginDate: beginDate,
            endDate: endDate,
            filename: ''
        };

        const organizationUsageList = await this.getSmsOrgnizationUsageList(reportForm);
        organizationUsageList.sort(this.smsOrganizationUsageAlphabeticalOrder);

        const data: SmsUsageListTemplate = {
            language: 'fi',
            outputFilename: reportForm.filename,
            templateName: 'SmsUsageList',
            organizationUsageList: organizationUsageList,
            organizationId: 0, // not used actually
            beginDate: moment(beginDate).format('L'),
            endDate: moment(endDate).format('L')
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async getMemberListAsync(
        organizationId: number,
        filter: ReportFilter,
        include?: ReportInclude,
        cancelToken?: CancelToken
    ): Promise<MemberList[]> {
        const reportParams: ReportParams = {
            filter: filter,
            include: include
        };

        const memberList = await axios.post<ReportParams, AxiosResponse<MemberList[]>>(
            `/reports/members/${organizationId}/list`,
            reportParams,
            { cancelToken: cancelToken }
        );

        // Default order
        memberList.data.sort(this.memberListAlphabeticalOrder);

        return memberList.data;
    }

    public async generateCredentialApplicationReportAsync(
        applicationId: number,
        filename: string,
        language: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: CredentialApplicationReportTemplate = {
            language: language,
            outputFilename: filename,
            templateName: 'CredentialApplicationReport',
            applicationId: applicationId,
            organizationId: 0
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async credentialApplicationNotesReportAsync(
        applicationId: number,
        filename: string,
        language: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: CredentialApplicationNotesReportTemplate = {
            language: language,
            outputFilename: filename,
            templateName: 'CredentialApplicationNotesReport',
            applicationId: applicationId,
            organizationId: 0
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async credentialApplicationCsvReportAsync(
        type?: number,
        filename?: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: CredentialApplicationCsvTemplate = {
            language: 'fi',
            outputFilename: filename ?? '',
            templateName: 'CredentialApplicationCsv',
            type: type,
            organizationId: 0
        };

        const csvParams: CsvParams = {
            template: data
        };

        return this.generateCsvFromTemplate(csvParams, cancelToken);
    }

    public async credentialApplicationAgendaAsync(
        applicationIds: number[],
        filename: string,
        language: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: CredentialApplicationAgendaTemplate = {
            language: language,
            outputFilename: filename,
            templateName: 'CredentialApplicationAgenda',
            organizationId: 0,
            applicationIds: applicationIds
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async credentialApplicationProcessingMemoAsync(
        date: Date,
        filename: string,
        language: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: CredentialApplicationProcessingMemoTemplate = {
            language: language,
            outputFilename: filename,
            templateName: 'CredentialApplicationProcessingMemo',
            organizationId: 0,
            date: date
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async credentialApplicationCardAsync(
        applicationId: number,
        filename: string,
        language: string,
        extraCard?: boolean,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: CredentialCardTemplate = {
            language: language,
            outputFilename: filename,
            templateName: 'CredentialCard',
            organizationId: 0,
            applicationId: applicationId,
            extraCard: extraCard
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async globalGroupRegisterCsvReportAsync(
        form: GlobalGroupRegisterReportForm,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: GlobalGroupRegisterCsvTemplate = {
            language: 'fi',
            outputFilename: form.filename ?? '',
            templateName: 'GlobalGroupRegisterCsv',
            type: form.type,
            organizationId: constants.RegionalCommunity.Id
        };

        const csvParams: CsvParams = {
            template: data,
            filter: form.filter,
            include: form.include
        };

        return this.generateCsvFromTemplate(csvParams, cancelToken);
    }

    private async getSmsOrgnizationUsageList(
        reportForm: SmsUsageListReportForm,
        cancelToken?: CancelToken
    ): Promise<SmsOrganizationUsage[]> {
        const reportParams: ReportParams = {
            filter: { beginDate: reportForm.beginDate, endDate: reportForm.endDate }
        };

        const smsUsageList = await axios.post<ReportParams, AxiosResponse<SmsOrganizationUsage[]>>(
            `/reports/smsusage`,
            reportParams,
            { cancelToken: cancelToken }
        );

        return smsUsageList.data;
    }

    public async congregationRegisterCsvReportAsync(
        form: CongregationRegisterReportForm,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: GlobalGroupRegisterCsvTemplate = {
            language: 'fi',
            outputFilename: form.filename ?? '',
            templateName: 'CongregationRegisterCsv',
            organizationId: constants.RegionalCommunity.Id
        };

        const csvParams: CsvParams = {
            template: data,
            filter: form.filter,
            include: form.include
        };

        return this.generateCsvFromTemplate(csvParams, cancelToken);
    }

    public async congregationRegisterPdfReportAsync(
        form: CongregationRegisterReportForm,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: GlobalGroupRegisterCsvTemplate = {
            language: 'fi',
            outputFilename: form.filename ?? '',
            templateName: 'CongregationRegistryReport',
            organizationId: constants.RegionalCommunity.Id
        };

        const pdfParams: PdfParams = {
            template: data,
            filter: form.filter,
            include: form.include
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async organizationBoardMemberAsync(
        organizationId: number,
        filename: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: OrganizationBoardMembersTemplate = {
            language: 'fi',
            outputFilename: filename,
            templateName: 'OrganizationBoardMembersReport',
            organizationId: organizationId
        };

        const pdfParams: PdfParams = {
            template: data
        };

        return this.generatePdfFromTemplate(pdfParams, cancelToken);
    }

    public async dvvMemberListAsync(
        organizationId: number,
        mode: number,
        filename: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: DvvMemberListCsvTemplate = {
            language: 'fi',
            outputFilename: filename,
            templateName: 'DvvMemberListCsv',
            organizationId: organizationId,
            mode: mode
        };

        const csvParams: CsvParams = {
            template: data
        };

        return this.generateCsvFromTemplate(csvParams, cancelToken);
    }

    public async dvvAddressListAsync(
        organizationId: number,
        mode: number,
        filename: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const data: DvvAddressListCsvTemplate = {
            language: 'fi',
            outputFilename: filename,
            templateName: 'DvvAddressListCsv',
            organizationId: organizationId,
            mode: mode
        };

        const csvParams: CsvParams = {
            template: data
        };

        return this.generateCsvFromTemplate(csvParams, cancelToken);
    }

    private async generatePdfFromTemplate(data: PdfParams, cancelToken?: CancelToken): Promise<AxiosResponse> {
        return axios.put('pdf/template/', data, { responseType: 'blob', cancelToken: cancelToken });
    }

    private async generateCsvFromTemplate(data: CsvParams, cancelToken?: CancelToken): Promise<AxiosResponse> {
        return axios.put('csv/template/', data, { responseType: 'blob', cancelToken: cancelToken });
    }

    private memberListAlphabeticalOrder(a: MemberList, b: MemberList) {
        if (!a || !b || !a.name || !b.name) return 0;
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
        return 0;
    }

    private smsOrganizationUsageAlphabeticalOrder(a: SmsOrganizationUsage, b: SmsOrganizationUsage) {
        if (!a || !b || !a.name || !b.name) return 0;
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
        return 0;
    }

    private memberListOrder(order?: 'name' | 'age' | 'birthday' | 'zipcode'): (a: MemberList, b: MemberList) => number {
        return (a: MemberList, b: MemberList): number => {
            if (!a || !b) return 0;
            if (order === 'name') {
                if (!a.name || !b.name) return 0;
                if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
                if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
            } else if (order === 'age') {
                if (!a.age || !b.age) return 0;
                if (parseInt(a.age) > parseInt(b.age)) return -1;
                if (parseInt(a.age) < parseInt(b.age)) return 1;
            } else if (order === 'birthday') {
                if (!a.birthDate || !b.birthDate) return 0;
                const ab = moment(a.birthDate, 'D.M.YYYY');
                const bb = moment(b.birthDate, 'D.M.YYYY');
                if (ab.dayOfYear() > bb.dayOfYear()) return 1;
                if (ab.dayOfYear() < bb.dayOfYear()) return -1;
            } else if (order === 'zipcode') {
                if (!a.zipCode || !b.zipCode) return 0;
                if (a.zipCode > b.zipCode) return 1;
                if (a.zipCode < b.zipCode) return -1;
            }
            return 0;
        };
    }
}

export default new ReportService();
