import React, { FC, useContext } from 'react';
import { FormControl, InputLabel, FormHelperText, Typography, Select, MenuItem } from '@mui/material';
import { useField } from 'formik';
import { KeyValue } from 'types/common';
import { LanguageContext } from 'contexts/languageContext';
import { FixedSizeList } from 'react-window';

interface FormFieldDropDownLargeListProps {
    label?: string;
    name: string;
    values: KeyValue[];
    placeholder?: string;
    disabledValues?: number | string | (number | string)[];
    hiddenValues?: (number | string)[];
    autoFocus?: boolean;
    readonly?: boolean;
    listWidth?: string | number;
}

const FormFieldDropDownLargeList: FC<FormFieldDropDownLargeListProps> = ({
    label,
    name,
    values,
    placeholder,
    disabledValues,
    hiddenValues,
    autoFocus,
    readonly,
    listWidth
}: FormFieldDropDownLargeListProps) => {
    const [field, meta, helpers] = useField(name);
    const { T } = useContext(LanguageContext);
    const [open, setOpen] = React.useState(false);
    const DEFAULT = 'no_set';

    const isDisabled = (key?: string | number): boolean => {
        if (!disabledValues || !key) return false;

        if (Array.isArray(disabledValues)) {
            return disabledValues.indexOf(key) !== -1;
        }

        return disabledValues === key;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const options = values
        .filter((item) => item.key !== DEFAULT)
        .filter((item) => !item.key || !hiddenValues || !hiddenValues?.includes(item.key));

    if (placeholder) {
        options.unshift({ key: '000', text: T(placeholder) });
    }

    const height = 36;

    return (
        <>
            <FormControl size="small" variant="outlined" fullWidth error={!!meta.error}>
                <InputLabel id={`select-${name}`} variant="outlined">
                    {label}
                </InputLabel>
                <Select
                    style={{ minWidth: 150 }}
                    labelId={`select-${name}`}
                    variant="outlined"
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value ?? ''}
                    name={field.name}
                    autoFocus={autoFocus}
                    disabled={readonly}
                    label={label}
                    displayEmpty
                    autoWidth
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    renderValue={(selected) => {
                        if ((selected.length === 0 || selected == 0) && placeholder) {
                            return null;
                        }

                        const selectedIndex = options.findIndex((o) => o.key == selected);
                        const selectedOption = options[selectedIndex];
                        const selectedText = selectedOption.value ? T(selectedOption.value) : selectedOption.text;
                        return <Typography>{selectedText}</Typography>;
                    }}
                >
                    <FixedSizeList
                        width={listWidth ?? 150}
                        height={300}
                        itemCount={options.length}
                        itemSize={height}
                        initialScrollOffset={options.indexOf(field.value) * height}
                    >
                        {({ index, style }) => (
                            <MenuItem
                                key={index}
                                style={style}
                                value={options[index].key}
                                disabled={isDisabled(options[index].key) || options[index].key == 0}
                                onClick={() => {
                                    helpers.setValue(options[index].key);
                                    handleClose();
                                }}
                            >
                                <Typography>
                                    {options[index].value ? T(options[index].value) : options[index].text}
                                </Typography>
                            </MenuItem>
                        )}
                    </FixedSizeList>
                    {/* Next line is wrapper for value that console warning disappears */}
                    <MenuItem value={field.value} sx={{ display: 'none' }}></MenuItem>
                </Select>
                {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
            </FormControl>
        </>
    );
};

export default FormFieldDropDownLargeList;
