import React, { FC, useEffect, useMemo, useState } from 'react';
import 'assets/App.scss';
import LanguageService from 'services/languageService';
import { LangString, LanguageContextInterface, LanguageProvider } from 'contexts/languageContext';
import WaitScreen from '../common/WaitScreen';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContextInterface, AuthenticatedUser, UserRole } from 'types/auth';
import { AuthProvider } from 'contexts/authContext';
import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/sv';
import PostalcodeService from 'services/postalcodeService';
import AuthService from 'services/authService';
import { AppLanguage } from 'types/common';
import { initLangString } from './initLanguageState';
import AppLayout from './AppLayout';
import CodeSetService from 'services/codeSetService';
import { initAxios } from './initAxios';
import defaults from 'config/defaults';
import PersonsService from 'services/personsService';
import { CookiesProvider } from 'react-cookie';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { ApplicationContextInterface, ApplicationProvider } from 'contexts/applicationContext';

const App: FC = () => {
    const [loaded, setLoaded] = useState(false);
    const [user, setStateUser] = useState<AuthenticatedUser>();
    const [language, setStateLanguage] = useState<AppLanguage>(defaults.Language);
    const navigate = useNavigate();
    const location = useLocation();
    const { handleError } = useErrorHandler();
    const [stateShowHeaders, setStateShowHeaders] = useState(true);

    // Init axios
    initAxios(navigate);

    const applicationState: ApplicationContextInterface = useMemo<ApplicationContextInterface>(() => {
        return {
            showHeaders: stateShowHeaders,
            setShowHeaders: (state: boolean) => {
                setStateShowHeaders(state);
            }
        };
    }, [stateShowHeaders]);

    const authState: AuthContextInterface = useMemo<AuthContextInterface>(() => {
        return {
            user: user,
            setUser: (newUser?: AuthenticatedUser) => {
                setStateUser(newUser);
            },
            hasRole: (role: UserRole | UserRole[]): boolean => {
                if (user && user.role) {
                    if (Array.isArray(role)) {
                        return role.includes(user?.role);
                    } else {
                        return role == user?.role;
                    }
                }
                return false;
            },
            isAdmin: (): boolean => {
                if (user && user.role) return user?.role === UserRole.Admin;
                return false;
            },
            isAdminOrClerk: (): boolean => {
                if (user && user.role) return user?.role === UserRole.Admin || user?.role === UserRole.Clerk;
                return false;
            },
            isAuthenticated: (): boolean => {
                return !!user && AuthService.isAuthenticated();
            },
            logout: (): void => {
                AuthService.logout();
                setStateUser(undefined);
            }
        };
    }, [user]);

    const languageState: LanguageContextInterface = useMemo<LanguageContextInterface>(() => {
        return {
            langStrings: initLangString(),
            languageCodes: LanguageService.getLanguageCodes(),
            countryCodes: LanguageService.getCountryCodes(),
            T: (key?: string): string => {
                if (!key) return '';

                const langStr = languageState.langStrings.find((item: LangString) => item.key === key);
                return langStr ? langStr.value : key;
            },
            TExt: async (key: string, lang: AppLanguage): Promise<string> => {
                const translations = await LanguageService.getLanguageStringsAsync(lang);
                const langStr = translations.find((item: LangString) => item.key === key);
                return langStr ? langStr.value : key;
            },
            setLanguageStrings: (strings: LangString[]): void => {
                languageState.langStrings = strings;
            },
            setLanguage: async (newLanguage: AppLanguage): Promise<void> => {
                // Load translation strings
                const translations = await LanguageService.getLanguageStringsAsync(newLanguage);

                languageState.setLanguageStrings(translations);
                moment.locale(newLanguage);
                setStateLanguage(newLanguage);
            }
        };
    }, []);

    useEffect(() => {
        // Error handlers
        window.onunhandledrejection = (error) => {
            // handle error
            if (error?.reason?.message && error?.reason?.message == 'Network Error') {
                handleError(
                    error,
                    'Verkkoyhteysvirhe, yritä hetken päästä uudelleen. Ellei silti toimi ilmoita ylläpidolle'
                );
            } else {
                handleError(error, 'Käsittelemätön virhe: ilmoita ylläpidolle');
            }
        };

        window.onerror = (error) => {
            // do smthing
            handleError(error, 'Käsittelemätön virhe: ilmoita ylläpidolle');
        };

        // Load translation strings
        const langPromise = languageState.setLanguage(language);

        // Preload postal codes to cache
        const postcodePromise = PostalcodeService.getAllPostalCodesAsync();

        // Preload countries
        const countriesPromise = CodeSetService.getCoutriesAsync();

        Promise.all([langPromise, postcodePromise, countriesPromise]).then(() => {
            setLoaded(true);
        });

        if (authState.isAuthenticated() === false) {
            AuthService.refreshUserAsync().then((refreshUser?: AuthenticatedUser) => {
                if (refreshUser) {
                    authState.setUser(refreshUser);

                    // Set language
                    PersonsService.getPersonAsync(refreshUser.personId).then((person) => {
                        if (person) languageState.setLanguage((person.language as AppLanguage) ?? defaults.Language);
                    });

                    navigate(location?.pathname ?? '/');
                }
            });
        }
    }, [language, languageState, authState, user]);

    if (user && !loaded) {
        return <WaitScreen backdrop />;
    }

    return (
        <ApplicationProvider value={applicationState}>
            <LanguageProvider value={languageState}>
                <AuthProvider value={authState}>
                    <CookiesProvider>
                        <AppLayout locale={language} />
                    </CookiesProvider>
                </AuthProvider>
            </LanguageProvider>
        </ApplicationProvider>
    );
};

export default App;
