import React, { ChangeEvent, FC, useContext } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { useField } from 'formik';
import { CredentialApplicationAttachment, Document } from 'types/sp-api';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import ButtonDelete from 'components/common/ButtonDelete';
import TypeHelper from 'utils/typeHelper';
import { v4 as uuidv4 } from 'uuid';

interface CredentialAttachmentLineProps {
    attachment: CredentialApplicationAttachment;
    edit: boolean;
    index: number;
    onEdit: (index?: number) => void;
    onRemove: (index: number) => void;
    onSelectFile: (index: number, file: File) => void;
    removeDisabled?: boolean;
}

const CredentialAttachmentLine: FC<CredentialAttachmentLineProps> = ({
    attachment,
    edit,
    index,
    onEdit,
    onRemove,
    onSelectFile,
    removeDisabled
}: CredentialAttachmentLineProps) => {
    const { T } = useContext(LanguageContext);
    const name = `attachmentList.${index}`;
    const [field, , helpers] = useField<CredentialApplicationAttachment>(name);
    const orginalAttachment = { ...attachment };
    const [doc, , docHelpers] = useField<Document>(name + '.document');

    const selectFile = (event: ChangeEvent<HTMLInputElement>): void => {
        if (!event.currentTarget.files) return;

        const filename = encodeURIComponent(event.currentTarget.files[0].name);
        docHelpers.setValue({ ...doc.value, fileName: filename, blobName: `CAA_${uuidv4()}_${filename}` });

        onSelectFile(index, event.currentTarget.files[0]);
    };

    if (edit) {
        return (
            <Grid container>
                <Grid item md={4} sx={{ pr: 2 }}>
                    <FormFieldDropDownCodeSet
                        name={`${name}.type`}
                        codeSet="credentialAttachment"
                        label=""
                        placeholder={T('CredentialAttachment_SelectType')}
                        hiddenValues={[8]}
                    />
                </Grid>
                <Grid container item md={5} flexDirection="row" alignItems="center" wrap="nowrap">
                    <Grid item xs="auto">
                        <label htmlFor="btn-upload">
                            <input
                                id="btn-upload"
                                name="btn-upload"
                                style={{ display: 'none' }}
                                type="file"
                                onChange={selectFile}
                            />
                            <Button
                                className="btn-choose"
                                variant="contained"
                                color="secondary"
                                component="span"
                                size="small"
                                disabled={!field.value.type}
                            >
                                {T('Common_Browse')}
                            </Button>
                        </label>
                    </Grid>
                    <Grid item overflow="clip" sx={{ mr: 1 }}>
                        <Typography sx={{ ml: 2 }} variant="body2" title={attachment.document?.fileName}>
                            {attachment.document?.fileName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item md={3} alignItems="center">
                    <Button
                        variant="contained"
                        onClick={() => onEdit(undefined)}
                        disabled={field.value.type == 0 || !doc.value.fileName}
                        size="small"
                        sx={{ mr: 2 }}
                    >
                        {T('Common_Save')}
                    </Button>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                            helpers.setValue(orginalAttachment);
                            onRemove(index);
                        }}
                        size="small"
                    >
                        {T('Common_Cancel')}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container item xs={12}>
            <Grid item md={4}>
                {T(TypeHelper.AttachmentTypeToName(attachment.type))}
            </Grid>
            <Grid item md={6}>
                <Typography>{attachment.document?.fileName}</Typography>
            </Grid>
            <Grid item md={2}>
                {/* <ButtonEdit onClick={() => onEdit(index)} small /> */}
                <ButtonDelete onClick={() => onRemove(index)} small disabled={removeDisabled} />
            </Grid>
        </Grid>
    );
};

export default CredentialAttachmentLine;
