import React, { FC, useContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';
import PageNotFound from './PageNotFound';
import ChurchView from 'components/churches/ChurchView';
import ProfileView from 'components/profile/ProfileView';
import NotificationsView from 'components/notifications/NotificationsView';
import AccessRightsView from 'components/AccessRightsView';
import ReportView from 'components/reports/ReportView';
import PersonView from 'components/persons/PersonView';
import ContactView from 'components/ContactView';
import PersonItem from 'components/persons/PersonItem';
import ChurchItem from 'components/churches/ChurchItem';
import RegistrationView from 'components/registration/RegistrationView';
import LoginView from 'components/auth/LoginView';
import ResetPasswordView from 'components/auth/ResetPasswordView';
import { AuthContext } from 'contexts/authContext';
import UserConsentsView from 'components/auth/UserConsentsView';
import LogoutView from 'components/auth/LogoutView';
import CookiePolicyView from 'components/CookiePolicyView';
import MobileAuthPolicyView from 'components/MobileAuthPolicyView';
import ForgottenPasswordView from 'components/auth/ForgottenPasswordView';
import HealthView from 'components/HealthView';
import SettingsView from 'components/settings/SettingsView';
import ArchiveView from 'components/archive/ArchiveView';
import ArchivePersonItem from 'components/archive/ArchivePersonItem';
import SecureMessagesView from 'components/securemessages/SecureMessagesView';
import SmsMessagesView from 'components/smsmessages/SmsMessagesView';
import CredentialApplicationView from 'components/credentialApplications/CredentialApplicationView';
import config from 'config/config';
import constants from 'config/constants';
import CredentialRegisterView from 'components/credentialRegister/CredentialRegisterView';
import CredentialRegisterItem from 'components/credentialRegister/CredentialRegisterItem';
import SeniorRegisterView from 'components/globalGroupRegister/SeniorRegisterView';
import PrisonEvangelistRegisterView from 'components/globalGroupRegister/PrisonEvangelistRegisterView';
import WebinarMembersRegisterView from 'components/globalGroupRegister/WebinarMembers';
import CongregationRegisterView from 'components/congregationRegister/CongregationRegisterView';
import LegacyReportView from 'components/legacyReport/LegacyReportView';
import AdminToolsView from 'components/admintools/AdminToolsView';
import BirthdayMessagesView from 'components/birthdaymessages/BirthdayMessagesView';

const AppRoutes: FC = () => {
    return (
        <Routes>
            <Route path="/health" element={<HealthView />} />
            <Route path="/cookiePolicy" element={<CookiePolicyView />} />
            <Route path="/mobileAuthPolicy" element={<MobileAuthPolicyView />} />
            <Route path="/login" element={<LoginView />} />
            <Route path="/logout" element={<LogoutView />} />
            <Route path="/forgottenPassword" element={<ForgottenPasswordView />} />
            <Route path="/resetPassword/:validationToken" element={<ResetPasswordView />} />
            <Route path="/registration/:validationToken" element={<RegistrationView />} />
            <Route path="/userConsents/:userId/:validationToken" element={<UserConsentsView />} />
            {config.CUSTOMER === constants.Customers.Shk && (
                <>
                    <Route path="/credentialApplication" element={<CredentialApplicationView />} />
                    <Route path="/raporttiTallennus" element={<LegacyReportView />} />
                </>
            )}

            <Route
                path="/churches"
                element={
                    <RequireAuth>
                        <ChurchView />
                    </RequireAuth>
                }
            />
            <Route
                path="/churches/search/:filter"
                element={
                    <RequireAuth>
                        <ChurchView />
                    </RequireAuth>
                }
            />
            <Route
                path="/churches/:churchId"
                element={
                    <RequireAuth>
                        <ChurchItem />
                    </RequireAuth>
                }
            />
            <Route
                path="/persons"
                element={
                    <RequireAuth>
                        <PersonView />
                    </RequireAuth>
                }
            />
            <Route
                path="/persons/search/:filter"
                element={
                    <RequireAuth>
                        <PersonView />
                    </RequireAuth>
                }
            />
            <Route
                path="/persons/:personId"
                element={
                    <RequireAuth>
                        <PersonItem />
                    </RequireAuth>
                }
            />
            <Route
                path="/communication/securemessages"
                element={
                    <RequireAuth>
                        <SecureMessagesView />
                    </RequireAuth>
                }
            />
            <Route
                path="/communication/securemessages/:messageId"
                element={
                    <RequireAuth>
                        <SecureMessagesView />
                    </RequireAuth>
                }
            />
            <Route
                path="/communication/securemessages/:messageId/:replyId/:senderId"
                element={
                    <RequireAuth>
                        <SecureMessagesView />
                    </RequireAuth>
                }
            />
            <Route
                path="/communication/smsmessages"
                element={
                    <RequireAuth>
                        <SmsMessagesView />
                    </RequireAuth>
                }
            />
            <Route
                path="/communication/smsmessages/:messageId"
                element={
                    <RequireAuth>
                        <SmsMessagesView />
                    </RequireAuth>
                }
            />
            <Route
                path="/communication/smsmessages/:messageId/:copyMessageId"
                element={
                    <RequireAuth>
                        <SmsMessagesView />
                    </RequireAuth>
                }
            />
            <Route
                path="/communication/birthdaymessages"
                element={
                    <RequireAuth>
                        <BirthdayMessagesView />
                    </RequireAuth>
                }
            />
            <Route
                path="/communication/birthdaymessages/:messageId"
                element={
                    <RequireAuth>
                        <BirthdayMessagesView />
                    </RequireAuth>
                }
            />
            <Route
                path="/accessRights"
                element={
                    <RequireAuth>
                        <AccessRightsView />
                    </RequireAuth>
                }
            />
            <Route
                path="/notifications"
                element={
                    <RequireAuth>
                        <NotificationsView />
                    </RequireAuth>
                }
            />
            <Route
                path="/notifications/:notificationId"
                element={
                    <RequireAuth>
                        <NotificationsView />
                    </RequireAuth>
                }
            />
            <Route
                path="/reports"
                element={
                    <RequireAuth>
                        <ReportView />
                    </RequireAuth>
                }
            />
            <Route
                path="/reports/:organizationId"
                element={
                    <RequireAuth>
                        <ReportView />
                    </RequireAuth>
                }
            />
            <Route
                path="/profile"
                element={
                    <RequireAuth>
                        <ProfileView />
                    </RequireAuth>
                }
            />
            <Route
                path="/contact"
                element={
                    <RequireAuth>
                        <ContactView />
                    </RequireAuth>
                }
            />
            <Route
                path="/settings"
                element={
                    <RequireAuth>
                        <SettingsView />
                    </RequireAuth>
                }
            />
            <Route
                path="/settings/:page"
                element={
                    <RequireAuth>
                        <SettingsView />
                    </RequireAuth>
                }
            />
            <Route
                path="/archive"
                element={
                    <RequireAuth>
                        <ArchiveView />
                    </RequireAuth>
                }
            />
            <Route
                path="/archive/persons/:personId"
                element={
                    <RequireAuth>
                        <ArchivePersonItem />
                    </RequireAuth>
                }
            />
            <Route
                path="/archive/persons/search/:filter"
                element={
                    <RequireAuth>
                        <ArchiveView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/credentials"
                element={
                    <RequireAuth>
                        <CredentialRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/credentials/search/:filter"
                element={
                    <RequireAuth>
                        <CredentialRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/credentials/:credentialId"
                element={
                    <RequireAuth>
                        <CredentialRegisterItem />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/credentials/flag/:flag"
                element={
                    <RequireAuth>
                        <CredentialRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/seniors"
                element={
                    <RequireAuth>
                        <SeniorRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/seniors/search/:filter"
                element={
                    <RequireAuth>
                        <SeniorRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/prisonEvangelists"
                element={
                    <RequireAuth>
                        <PrisonEvangelistRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/prisonEvangelists/search/:filter"
                element={
                    <RequireAuth>
                        <PrisonEvangelistRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/webinarMembers"
                element={
                    <RequireAuth>
                        <WebinarMembersRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/webinarMembers/search/:filter"
                element={
                    <RequireAuth>
                        <WebinarMembersRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/congregations"
                element={
                    <RequireAuth>
                        <CongregationRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/registers/congregations/search/:filter"
                element={
                    <RequireAuth>
                        <CongregationRegisterView />
                    </RequireAuth>
                }
            />
            <Route
                path="/admintools"
                element={
                    <RequireAuth>
                        <AdminToolsView />
                    </RequireAuth>
                }
            />

            <Route
                path="/"
                element={
                    <RequireAuth>
                        <Dashboard />
                    </RequireAuth>
                }
            />
            <Route path="/pageNotFound" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/pageNotFound" replace />} />
        </Routes>
    );
};

function RequireAuth({ children }: { children: JSX.Element }) {
    const authCtx = useContext(AuthContext);
    const location = useLocation();

    if (authCtx.isAuthenticated() === false) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default AppRoutes;
