import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Container,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { BirthdayMessage } from 'types/sp-api';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import BirthdayMessagesService from 'services/birthdayMessagesService';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import { AuthContext } from 'contexts/authContext';
import WaitScreen from 'components/common/WaitScreen';
import Helper from 'utils/typeHelper';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CakeIcon from '@mui/icons-material/Cake';
import constants from 'config/constants';
import BirthdayMessageItem from './BirthdayMessageItem';

const BirthdayMessagesView: FC = () => {
    const { T } = useContext(LanguageContext);
    const { user, isAdmin } = useContext(AuthContext);
    const { handleError } = useErrorHandler();
    const [birthdaymessage, setBirthdaymessage] = useState<BirthdayMessage>();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(
        isAdmin() ? constants.RegionalCommunity.Id : user?.homeOrganizationId ?? 0
    );

    const getBirthdayMessage = useCallback(
        async (orgId: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const birthdaymessages = await BirthdayMessagesService.getOrganizationMessageAsync(
                    orgId,
                    cancelRef.current.token
                );
                if (cancelRef.current) {
                    setBirthdaymessage(birthdaymessages?.[0]);
                }
            } catch (error) {
                handleError(error, T('Error_BirthdayMessagesQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getBirthdayMessage(selectedOrganizationId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getBirthdayMessage, selectedOrganizationId]);

    if (!user?.homeOrganizationId) {
        return <WaitScreen />;
    }

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle icon={CakeIcon} title={T('BirthdayMessages_Title')} />
            </Grid>
            {user.organizations.length > 1 && (
                <Grid container style={{ marginBottom: 12 }}>
                    <Grid container item xs={6} spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{T('BirthdayMessage_SelectTargetChurch')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl size="small" variant="outlined" fullWidth>
                                <InputLabel variant="outlined">{T('BirthdayMessage_TargetChurch')}</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(event: SelectChangeEvent<number>) => {
                                        setSelectedOrganizationId(event.target.value as number);
                                    }}
                                    value={selectedOrganizationId}
                                    label={T('BirthdayMessage_TargetChurch')}
                                >
                                    {user.organizations.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                            {Helper.IsYhdyskunta(item.type) && (
                                                <ApartmentIcon fontSize="small" style={{ marginLeft: 8 }} />
                                            )}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box m={3} />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={3}>
                <Grid container item xs={7}>
                    <BirthdayMessageItem message={birthdaymessage} organizationId={selectedOrganizationId} />
                </Grid>
                <Grid container item xs={5}>
                    <Paper elevation={1}>
                        <Grid container padding={2} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="caption" color="GrayText" fontSize={15} fontWeight={'bold'}>
                                    {T('BirthdayMessage_HelpTextTitle')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" color="GrayText">
                                    {T('BirthdayMessage_HelpText')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" color="GrayText">
                                    {T('BirthdayMessage_HelpText2')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} m={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" color="GrayText" fontStyle={'italic'}>
                                    {T('BirthdayMessage_MessageKey_Firstname')}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" color="GrayText">
                                    {T('BirthdayMessage_MessageHint_Firstname')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" color="GrayText" fontStyle={'italic'}>
                                    {T('BirthdayMessage_MessageKey_Lastname')}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" color="GrayText">
                                    {T('BirthdayMessage_MessageHint_Lastname')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" color="GrayText" fontStyle={'italic'}>
                                    {T('BirthdayMessage_MessageKey_Fullname')}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" color="GrayText">
                                    {T('BirthdayMessage_MessageHint_Fullname')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} m={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                Esimerkkiviesti:
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" color="GrayText">
                                    Paljon onnea &lt;kokonimi&gt;!
                                    <br />
                                    <br />
                                    &lt;etunimi&gt;, Lue sanankohta Psalmi 118:24–25 syntymäpäiväsi piristykseksi.
                                    <br />
                                    <br />
                                    Siunattua juhlapäivää,
                                    <br />
                                    terveisin, pastorisi Pekka Virtanen
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BirthdayMessagesView;
