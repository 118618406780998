import React, { FC, useContext, useState } from 'react';
import { Grid } from '@mui/material';
import DownloadFile from 'components/common/DownloadFile';
import LoadButton from 'components/common/LoadButton';
import { LanguageContext } from 'contexts/languageContext';
import { AxiosResponse } from 'axios';
import ReportService from 'services/reportService';

interface ReportDvvAddressListProps {
    organizationId: number;
    organizationName: string;
}

const ReportDvvAddressList: FC<ReportDvvAddressListProps> = ({
    organizationId,
    organizationName
}: ReportDvvAddressListProps) => {
    const [downloadFileCsv, setDownloadFileCsv] = useState(false);
    const { T } = useContext(LanguageContext);
    const filename = T('Report_DvvAddressListCsvFile').replace('{0}', organizationName);

    const fetchFile = async (filename: string): Promise<AxiosResponse> => {
        const mode = 1; // TODO rajaa onko koko jäsenistö vai suomen/ruotsin kielisten osuus (advk)
        return ReportService.dvvAddressListAsync(organizationId, mode, filename);
    };

    return (
        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
            <Grid container item xs={12} spacing={3} justifyContent="space-between">
                <Grid item>
                    <LoadButton
                        onClick={() => {
                            setDownloadFileCsv(true);
                        }}
                        loading={downloadFileCsv}
                    >
                        {T('Report_DvvAddressListCsvBtn')}
                    </LoadButton>
                    {downloadFileCsv && (
                        <DownloadFile
                            fetchFile={() => fetchFile(filename)}
                            filename={filename}
                            onSuccess={() => setDownloadFileCsv(false)}
                            filetype="text/csv"
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReportDvvAddressList;
